.list-card-wrapper {
  border-radius: 7px;

  .list-card {
    position: relative;

    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    font-size: 12px;

    min-height: 110px;

    color: var(--arxs-secondary-color);
    background-color: var(--arxs-list-card-background-color);
    box-shadow: 0 2px 4px 0 var(--arxs-border-shadow-color);

    .list-card-left {
      margin-right: 10px;
      font-size: 60px;

      .list-card-image {
        max-width: 100px;
        width: 86px;
      }

      i {
        font-size: 36px;
        color: var(--arxs-status-warning-color);
      }
    }

    .list-card-right {
      width: 100%;
      display: flex;
      flex-direction: column;

      h1 {
        i {
          font-size: 12px;
        }

        font-size: 12px;
        margin-bottom: 10px;
      }

      .list-card-header {
        display: flex;
        gap: 5px;
        margin-bottom: 5px;

        >div {
          width: fit-content;
          color: var(--arxs-secondary-color);
          background-color: var(--arxs-border-shadow-color);
          border-radius: 3px;
          padding: 3px;

          &.error {
            color: var(--arxs-status-error-color);
            background-color: var(--arxs-status-error-color-background);
          }

          &.period {
            color: var(--arxs-status-active-color);
            background-color: var(--arxs-status-active-color-background);
          }
        }

        .planning-progress-container {
          flex-grow: 1;
          background-color: var(--arxs-icon-background-color);
          border-radius: 5px;

          .planning-progress {
            border-radius: 5px;
          }
        }

        .deadline {
          display: flex;
          gap: 4px;
          background-color: var(--arxs-status-error-color-background);
          color: var(--arxs-status-error-color);

          .deadline-exclamation {
            font-weight: 600;
          }
        }
      }

      .list-card-subheader {
        display: flex;

        >div {
          color: var(--arxs-primary-color);
          font-weight: bold;
          font-size: 11px;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 35px;
        }
      }

      h2 {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;

        .list-card-title {
          color: var(--arxs-primary-color);
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 35px;
        }
      }

      .list-card-bullet {
        color: var(--arxs-secondary-color);
      }

      .list-card-ref {
        margin-bottom: 5px;
      }

      .list-card-details {
        >div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        font-size: 12px;
        line-height: 19px;

        .list-card-details-detail.emergencyPhone {
          color: red;
          font-weight: bold;
        }
      }

      .list-card-footer {
        flex-grow: 1;
        display: flex;
        align-items: end;
        justify-content: end;

        .list-card-form-count {
          display: flex;
          gap: 5px;
          align-items: center;
          font-weight: 800;
          color: var(--arxs-status-active-color);

          .fa-check {
            padding: 4px 5px;
            border-radius: 50%;
            color: white;
            background: var(--arxs-status-completed-color);
          }
        }
      }

      overflow: hidden;
    }

    .list-card-new {
      display: flex;
      height: 100%;
      width: 100%;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      i.fa-plus {
        font-size: 20px;
      }
    }

    .user-card {
      flex: 0 0 310px;

      padding: 10px;
      //margin: 10px 25px 10px 25px;

      display: flex;
      align-items: flex-start;
      flex-direction: row;

      color: var(--arxs-secondary-color);
      background-color: var(--arxs-list-card-background-color);
      box-shadow: 0 2px 4px 0 var(--arxs-border-shadow-color);
      border-radius: 5px;

      min-height: 60px;
      max-height: 60px;

      .user-card-icon {
        font-size: 40px;
      }

      .selectable {
        &:hover {
          cursor: pointer;
        }
      }

      .user-card-names {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-weight: bold;

        .user-card-name {
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .user-card-username {
          flex-grow: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
        }

        .user-card-phones {
          display: flex;
          flex-direction: row;

          .user-card-phone {
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 10px;
          }

          .user-card-phone.emergency {
            color: red;
          }
        }
      }

      .avatar {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

      .user-card-actions {
        i {
          cursor: pointer;
        }
      }
    }
  }
}

.list-card-wrapper:hover {
  border: 1px solid var(--arxs-status-active-color);
  box-shadow: 0 0 10px 0 rgba(77, 121, 255, 0.7);
}

.list-card-wrapper.selected {
  border: 3px solid var(--arxs-status-active-color);
  box-shadow: 0 0 10px 0 rgba(77, 121, 255, 0.7);
}

.mini-card {
  flex: 0 0 220px;

  padding: 10px;

  display: flex;
  align-items: flex-start;
  flex-direction: row;

  color: var(--arxs-secondary-color);
  background-color: var(--arxs-list-card-background-color);
  box-shadow: 0 2px 4px 0 var(--arxs-border-shadow-color);
  border-radius: 5px;

  min-height: 35px;
  max-height: 35px;

  .mini-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--arxs-secondary-color);
    margin-right: 10px;
  }

  >i:first-child {
    font-size: 16px;
    margin-right: 10px;
  }

  .selectable {
    &:hover {
      cursor: pointer;
    }
  }

  .mini-card-names {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-weight: bold;

    .mini-card-name {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mini-card-username {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
    }
  }
}

.user-card {
  flex: 0 0 310px;

  padding: 10px;
  //margin: 10px 25px 10px 25px;

  display: flex;
  align-items: flex-start;
  flex-direction: row;

  color: var(--arxs-secondary-color);
  background-color: var(--arxs-list-card-background-color);
  box-shadow: 0 2px 4px 0 var(--arxs-border-shadow-color);
  border-radius: 5px;

  min-height: 60px;
  max-height: 60px;

  .user-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: var(--arxs-secondary-color);
    margin-right: 10px;
  }

  >i:first-child {
    font-size: 40px;
    margin-right: 10px;
  }


  .selectable {
    &:hover {
      cursor: pointer;
    }
  }

  .user-card-names {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-weight: bold;

    .user-card-name {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .user-card-username {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 10px;
    }

    .user-card-phones {
      display: flex;
      flex-direction: row;

      .user-card-phone {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 10px;
      }

      .user-card-phone.emergency {
        color: red;
      }
    }
  }

  .user-card-i-container {
    display: flex;
    flex-direction: column;
    height: 45px;
    justify-content: space-between;
  }

  .user-card-actions {
    display: flex;
    gap: 0 5px;
    font-size: 12px;
    height: 100%;
  }

  .user-card-signature {
    width: fit-content;
    border-radius: 3px;
    padding: 3px;
    font-size: 12px;
    color: var(--arxs-secondary-color);
    background-color: var(--arxs-border-shadow-color);
    i.signed { margin-right: 5px; }
    font-weight: normal;;
  }

  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .user-card-actions {
    i {
      cursor: pointer;
    }
  }
}