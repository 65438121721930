.form-item-editor-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 10px;
    user-select: none;
    background-color: var(--arxs-background-color);
    margin-top: 10px;

    .form-item-editor-drag-handle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-top: 10px;

        cursor: move;

        &:hover {
            background: var(--arxs-border-shadow-color);
        }
    }

    .form-item-editor-control {
        width: 100%;
        padding: 10px;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 10px;

        textarea {
            min-height: 29px;
            line-height: 1.5;
        }

        .empty {
            width: 100%;
        }

        .form-item-control-type {
            min-width: 150px;
        }

        .form-item-control-container {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            gap: 5px;

            .textarea {
                min-height: 29px;
                padding-bottom: 5px;
            }

            > div > div {
                width: 100%;

                .drag-handle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    cursor: move;
                }
            }

            .point-of-attention {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-bottom: 5px;
                align-items: flex-start;
                grid-gap: 5px;

                .point-of-attention-type {
                    width: 100%;
                }

                .point-of-attention-container {
                    width: 100%;

                    .point-of-attention-line {
                        display: flex;
                        flex-direction: row;
                        gap: 5px;

                        .point-of-attention-value {
                            width: 100%;
                        }

                        .point-of-attention-actions {
                            display: flex;
                            flex-direction: row;
                            min-width: 20px;

                            .point-of-attention-action {
                                margin-top: 5px;
                                cursor: pointer;
                                color: var(--arxs-icon-inactive-color);

                                &:hover {
                                    color: var(--arxs-icon-color);
                                }
                            }
                        }
                    }
                }
            }

            .rpms {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-bottom: 5px;
                align-items: flex-start;
                grid-gap: 5px;

                .rpm-container {
                    display: flex;
                    gap: 5px;
                    flex-direction: row;
    
                    .rpm {
                        display: flex;
                        flex-direction: row;
                        gap: 5px;
                        padding-bottom: 5px;
                        align-items: center;
                        flex-grow: 1;
    
                        .icon-overview {
                            min-width: 62px;
    
                            .icon-content {
                                margin-bottom: 0px;
                                margin-top: 0px;
                                gap: 0px;
                            }
                        }
    
                        .rpm-value {
                            width: 100%;
                            max-height: 32px;
                        }
    
                        .rpm-actions {
                            display: flex;
                            flex-direction: row;
                            min-width: 20px;
    
                            .rpm-action {
                                margin-top: 5px;
                                cursor: pointer;
                                color: var(--arxs-icon-inactive-color);
    
                                &:hover {
                                    color: var(--arxs-icon-color);
                                }
                            }
                        }
                    }
                }
            }

        
            .periodical {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-bottom: 5px;
                align-items: flex-start;
                grid-gap: 5px;

                .periodical-identification {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    align-items: flex-start;
                    width: 100%;

                    .dropdown.periodical-module {
                        flex-basis: 150px;
                    }
    
                    textarea {
                        flex-grow: 1;
                        width: auto;
                    }
                }

                .periodical-details {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    padding-bottom: 5px;
                    align-items: flex-start;
                    width: 100%;
                }

                .dropdown.periodical-executor {
                    width: 200px;
                    min-width: 200px !important;
                }

                .dropdown.periodical-frequency {
                    width: 120px;
                    min-width: 120px;
                }

                .periodical-number {
                    width: 60px;
                }

                .periodical-actions {
                    display: flex;
                    flex-direction: row;
                    min-width: 20px;

                    .periodical-action {
                        margin-top: 5px;
                        cursor: pointer;
                        color: var(--arxs-icon-inactive-color);

                        &:hover {
                            color: var(--arxs-icon-color);
                        }
                    }
                }
            }

            .recommendation {
                display: flex;
                flex-direction: row;
                gap: 5px;
                padding-bottom: 5px;
                align-items: center;
                grid-gap: 5px;

                .dropdown {
                    width: 100%;
                }
            }

            .prerecommendation {
                display: flex;
                flex-direction: column;
                gap: 5px;
                padding-bottom: 5px;
                align-items: flex-start;
                grid-gap: 5px;

                .prerecommendation-type {
                    width: 100%;
                }

                .prerecommendation-container {
                    width: 100%;

                    .prerecommendation-line {
                        display: flex;
                        flex-direction: row;
                        gap: 5px;

                        .prerecommendation-actions {
                            display: flex;
                            flex-direction: row;
                            min-width: 20px;

                            .prerecommendation-action {
                                margin-top: 5px;
                                cursor: pointer;
                                color: var(--arxs-icon-inactive-color);

                                &:hover {
                                    color: var(--arxs-icon-color);
                                }
                            }
                        }
                    }
                }
            }

            .riskAnalysis {
                display: flex;
                flex-direction: row;
                gap: 5px;
                padding-bottom: 5px;
                align-items: center;
                grid-gap: 5px;

                i {
                    font-size: 24px;
                }

                i.fas.fa-thumbs-up.status-active {
                    rotate: -45deg;
                }

                i.fas.fa-thumbs-up.status-warning {
                    rotate: -90deg;
                }

                i.fas.fa-thumbs-up.status-severe {
                    rotate: -135deg;
                }

                i.fas.fa-thumbs-up.status-error {
                    rotate: 180deg;
                }

                .riskAnalysis-action {
                    size: 16px;
                    margin-top: 5px;
                    cursor: pointer;
                    color: var(--arxs-icon-inactive-color);

                    &:hover {
                        color: var(--arxs-icon-color);
                    }

                    i {
                        font-size: 16px;
                    }
                }
            }

            .risk-measurement-container {
                display: flex;
                flex-direction: column;
                gap: 5px;

                .risk-measurement-method {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    align-items: center;
                    label {
                        min-width: 50px;
                    }
                }

                .risk-measurement {
                    .risk-measurement-line {
                        .risk-measurement-header {
                            display: flex;
                            flex-direction: column;

                            .risk-measurement-header-line {
                                display: flex;
                                flex-direction: row;
                                gap: 5px;
                                padding-bottom: 5px;
                                align-items: center;
                                justify-content: space-between;

                                label {
                                    min-width: 50px;
                                }

                                .risk-measurement-header-line-values {
                                    display: flex;
                                    flex-direction: row;
                                    gap: 5px;
                                    align-items: center;

                                    label {
                                        min-width: 50px;
                                    }
                                }

                                .risk-measurement-actions {
                                    display: flex;
                                    flex-direction: row;
                                    min-width: 20px;

                                    .risk-measurement-action {
                                        margin-top: 5px;
                                        cursor: pointer;
                                        color: var(--arxs-icon-inactive-color);

                                        &:hover {
                                            color: var(--arxs-icon-color);
                                        }
                                    }
                                }
                            }
                        }

                        .risk-measurement-value {
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            padding-bottom: 5px;
                            align-items: center;
                            grid-gap: 5px;

                            label {
                                min-width: 180px;
                            }

                            i {
                                font-size: 24px;
                            }

                            i.fas.fa-thumbs-up.status-active {
                                rotate: -45deg;
                            }

                            i.fas.fa-thumbs-up.status-warning {
                                rotate: -90deg;
                            }

                            i.fas.fa-thumbs-up.status-severe {
                                rotate: -135deg;
                            }

                            i.fas.fa-thumbs-up.status-error {
                                rotate: 180deg;
                            }

                            .riskAnalysis-action {
                                size: 16px;
                                margin-top: 5px;
                                cursor: pointer;
                                color: var(--arxs-icon-inactive-color);

                                &:hover {
                                    color: var(--arxs-icon-color);
                                }

                                i {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }

            .link-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 10px;

                .module-selector {
                    flex: 0 1 calc(50% - 5px);
                }

                .action-selector {
                    flex: 0 1 calc(50% - 5px);
                }

                .object-title {
                    flex-basis: 100%;
                }
            }
        }
    }

    .form-item-editor-required {
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .form-item-editor-actions {
        margin-top: 15px;
        cursor: pointer;
        color: var(--arxs-icon-inactive-color);

        &:hover {
            color: var(--arxs-icon-color);
        }
    }
}

.form-item-control-add-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    color: var(--arxs-icon-color);
    background-color: var(--arxs-background-color);
    gap: 5px;
}

@media only screen and (max-width: 1223px) {
    .form-item-editor-row {
        .form-item-editor-control {
            flex-direction: column;

            .form-item-control-type {
                min-width: 150px;
                width: 100%;
            }

            .form-item-control-container { 
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .form-item-editor-row {
        .form-item-editor-control {
            flex-direction: column;

            .form-item-control-type {
                min-width: 150px;
                width: 100%;
            }

            .form-item-control-container { 
                width: 100%;

                .link-container {
                    flex-direction: column;
                    
                    .module-selector {
                        flex-basis: inherit;
                    }
                    .action-selector {
                        flex-basis: inherit;
                        width: 100%;
                    }
                }
            }
        }
    }
}
